<template>
  <div class="animated fadeIn container">
    <h1>추천코드 관리</h1>
    <b-input-group class="mt-4" style="max-width:300px">
      <b-input-group-prepend>
        <b-input-group-text>
          <i class="icon-tag"></i>
        </b-input-group-text>
      </b-input-group-prepend>
      <b-form-input
        type="text"
        class="form-control"
        placeholder="태그"
        autocomplete="recommendCode"
        v-model="recommendCode"
      />
      <b-btn variant="primary" class="fl" @click.prevent="create">생성</b-btn>
    </b-input-group>
    <table class="border-table mt-4" v-if="items.length > 0">
      <thead>
        <tr>
          <td width="120px">추천코드</td>
          <td width="120px">요금</td>
          <td>설명</td>
          <td width="80px">회원수</td>
          <td width="120px">생성일</td>
          <td width="120px">편집</td>
        </tr>
      </thead>
      <tbody>
        <tr :key="item.id" v-for="(item, index) in items">
          <td>
            {{ item.code }}
          </td>
          <td>
            <input
              type="number"
              v-model="item.smsFee"
              style="width:60px;padding:0 5px"
              @blur="blurSmsFee(index, item)"
              :ref="'smsFee-' + index"
            />
          </td>
          <td>
            <input
              v-model="item.description"
              style="padding:0 5px"
              @blur="blurDescription(index, item)"
              :ref="'description-' + index"
            />
          </td>
          <td>
            <b-btn @click="showModal(item.user)">{{ item.user.length }}</b-btn>
          </td>
          <td>
            {{ item.createdAt | dateShortFormat }}
          </td>
          <td>
            <b-btn class="btn-danger" @click="deleteCode(item.id)">삭제</b-btn>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- <b-pagination align=" center " :total-rows="totalCount" v-model="currentPage" :per-page="20" :limit="10" class="mt-4 "></b-pagination> -->

    <!-- Modal Component -->
    <b-modal ref="userModal" title="추천코드 회원 명단" centered ok-only>
      <ul>
        <li :key="user.id" v-for="user in users">
          <a :href="'/admin/users/' + user.id">{{ user.username }}({{ user.email }})</a>
        </li>
      </ul>
    </b-modal>
  </div>
</template>

<script>
import RecommendCodeService from '@/services/RecommendCodeService'

export default {
  name: 'RecommendCodes',
  data() {
    return {
      items: [],
      // totalCount: 0,
      // currentPage: 1,
      recommendCode: '',
      users: [],
    }
  },
  mounted() {
    document.getElementsByClassName('app-body')[0].classList.add('bg-white')
    this.getList()
  },
  methods: {
    blurSmsFee(index, item) {
      RecommendCodeService.update(item.id, item).then(function(response) {
        if (response.status != 200) {
          this.$refs['smsFee-' + index][0].focus()
        }
      })
    },
    blurDescription(index, item) {
      RecommendCodeService.update(item.id, item).then(function(response) {
        if (response.status != 200) {
          this.$refs['smsFee-' + index][0].focus()
        }
      })
    },
    async getList() {
      const response = await RecommendCodeService.list({
        // page: this.currentPage,
      })
      if (response.status == 200) {
        this.items = response.data.list
        this.totalCount = response.data.totalCount
      }
    },
    async create() {
      if (this.recommendCode.length == 0) {
        alert('값을 입력해주세요')
        return
      }
      const response = await RecommendCodeService.create({
        code: this.recommendCode,
      })
      if (response.status == 200) {
        alert('성공')
        location.reload()
      }
    },
    async deleteCode(id) {
      if (!confirm('삭제하시겠습니까?')) {
        return
      }
      const response = await RecommendCodeService.delete(id)
      if (response.status == 200) {
        alert('성공')
        location.reload()
      }
    },
    showModal(users) {
      this.users = users
      this.$refs.userModal.show()
    },
  },
  watch: {
    currentPage: function() {
      this.getList()
    },
  },
}
</script>
